import AOS from 'aos';

class AnimateOnScroll {
  constructor() {
    AOS.init({
      once: true
    });
  }

  init() {
    window.addEventListener('load', AOS.refresh);
  }
}

new AnimateOnScroll().init();
